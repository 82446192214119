@import "variables";
@import "responsive";

.progress-bar {
  height: 4px;
  background-color: $grey-7;
  position: relative;
  margin: 8px 0;
  border-radius: 2px;

  .fill {
    height: 100%;
    width: 50%; // Set by JS
    border-radius: 2px;
    transition: width 300ms ease-in-out;

    &.under {
      background-color: $primary-blue;
    }

    &.close {
      background-color: $primary-orange;
    }

    &.over {
      background-color: $primary-red;
    }
  }

  .threshold {
    border-left: 2px dotted $grey-3;
    margin-left: -2px;
    height: 20px;
    position: absolute;
    top: -8px;
    left: 50%; // Set by JS
  }
}

.summary {
  width: 100%;
  text-align: right;
  margin: 16px 0;

  tr {
    td {
      padding: 4px 0;
      line-height: 20px;
    }

    &:first-child td {
      padding-bottom: 12px;
    }
  }

  td:first-child {
    text-align: left;
  }

  td:last-child {
    width: 55px;

    @include sm {
      width: 65px;
    }
  }
}

.edit-button {
  margin: -6px 0; // Keep row w/button same height as rest
}

.total-costs {
  font-weight: 600;

  &.under {
    color: $grey-1;
  }

  &.close {
    color: $primary-orange;
  }

  &.over {
    color: $primary-red;
  }
}
