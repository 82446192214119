@import "variables";

.report-issue-banner {
  text-align: center;
  margin-bottom: 32px;
  padding: 10px 8px 0;
  font-weight: 500;
  font-size: 14px;

  .report-issue-buttons {
    display: inline-flex;
    gap: 10px;
  }
}
