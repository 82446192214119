@import "normalize";
@import "fonts";
@import "variables";
@import "responsive";

html {
  height: 100%;
  height: -webkit-fill-available; // Fix height on iOS Safari
  background: #fafafa; //bgUiContainer

  @include lg {
    height: 100%;
  }
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available; // Fix height on iOS Safari
  font-family: "Inter", sans-serif;
  color: $grey-1;
  -webkit-font-smoothing: antialiased;
  padding: 0 !important;
  @include lg {
    min-height: 100%;
  }

  &:global(.ReactModal__Body--open) {
    overflow: hidden;
  }
}

:global {
  #app {
    min-height: 100%;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;

    @include lg {
      min-height: initial;
    }
  }
}

* {
  box-sizing: border-box;
}
