@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Inter-Regular.woff2") format("woff2"),
    url("../fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/Inter-Italic.woff2") format("woff2"),
    url("../fonts/Inter-Italic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Inter-Medium.woff2") format("woff2"),
    url("../fonts/Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/Inter-MediumItalic.woff2") format("woff2"),
    url("../fonts/Inter-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Inter-SemiBold.woff2") format("woff2"),
    url("../fonts/Inter-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/Inter-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/Inter-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Inter-Bold.woff2") format("woff2"),
    url("../fonts/Inter-Bold.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/Inter-BoldItalic.woff2") format("woff2"),
    url("../fonts/Inter-BoldItalic.woff") format("woff");
}
