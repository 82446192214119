@import "variables";

.icon {
  &.clickable {
    cursor: pointer;
  }

  &.grey {
    &.stroke {
      path {
        stroke: $grey-4;
      }
      &.hoverable {
        &:hover {
          path {
            stroke: $grey-1;
          }
        }
      }
    }

    &.fill {
      path {
        fill: $grey-4;
      }
      &.hoverable {
        &:hover {
          path {
            fill: $grey-1;
          }
        }
      }
    }
  }

  &.dark-grey {
    &.stroke {
      path {
        stroke: $grey-1;
      }
      &.hoverable {
        &:hover {
          path {
            stroke: $grey-4;
          }
        }
      }
    }

    &.fill {
      path {
        fill: $grey-1;
      }
      &.hoverable {
        &:hover {
          path {
            fill: $grey-4;
          }
        }
      }
    }
  }

  &.light-grey {
    &.stroke {
      path {
        stroke: $grey-5;
      }
      &.hoverable {
        &:hover {
          path {
            stroke: $grey-1;
          }
        }
      }
    }

    &.fill {
      path {
        fill: $grey-5;
      }
      &.hoverable {
        &:hover {
          path {
            fill: $grey-1;
          }
        }
      }
    }
  }

  &.blue {
    &.stroke {
      path {
        stroke: $primary-blue;
      }
      &.hoverable {
        &:hover {
          path {
            stroke: darken($primary-blue, 10%);
          }
        }
      }
    }

    &.fill {
      path {
        fill: $primary-blue;
      }
      &.hoverable {
        &:hover {
          path {
            fill: darken($primary-blue, 10%);
          }
        }
      }
    }
  }

  &.white {
    &.stroke {
      path {
        stroke: $grey-9;
      }
      &.hoverable {
        &:hover {
          path {
            stroke: $grey-4;
          }
        }
      }
    }

    &.fill {
      path {
        fill: $grey-9;
      }
      &.hoverable {
        &:hover {
          path {
            fill: $grey-4;
          }
        }
      }
    }
  }

  &.black {
    &.stroke {
      path {
        stroke: black;
      }
      &.hoverable {
        &:hover {
          path {
            stroke: black;
          }
        }
      }
    }

    &.fill {
      path {
        fill: black;
      }
      &.hoverable {
        &:hover {
          path {
            fill: black;
          }
        }
      }
    }
  }

  &.grey-2 {
    &.stroke {
      path {
        stroke: $grey-2;
      }
      &.hoverable {
        &:hover {
          path {
            stroke: $grey-1;
          }
        }
      }
    }

    &.fill {
      path {
        fill: $grey-2;
      }
      &.hoverable {
        &:hover {
          path {
            fill: $grey-1;
          }
        }
      }
    }
  }

  &.grey-3 {
    &.stroke {
      path {
        stroke: $grey-3;
      }
      &.hoverable {
        &:hover {
          path {
            stroke: $grey-1;
          }
        }
      }
    }

    &.fill {
      path {
        fill: $grey-3;
      }
      &.hoverable {
        &:hover {
          path {
            fill: $grey-1;
          }
        }
      }
    }
  }

  &.grey-4 {
    &.stroke {
      path {
        stroke: $grey-4;
      }
      &.hoverable {
        &:hover {
          path {
            stroke: $grey-1;
          }
        }
      }
    }

    &.fill {
      path {
        fill: $grey-4;
      }
      &.hoverable {
        &:hover {
          path {
            fill: $grey-1;
          }
        }
      }
    }
  }

  &.dark-blue {
    &.stroke {
      path {
        stroke: $dark-blue;
      }
      &.hoverable {
        &:hover {
          path {
            stroke: $dark-blue;
          }
        }
      }
    }

    &.fill {
      path {
        fill: $dark-blue;
      }
      &.hoverable {
        &:hover {
          path {
            fill: $dark-blue;
          }
        }
      }
    }
  }

  &.yellow {
    &.stroke {
      path {
        stroke: $primary-orange;
      }
      &.hoverable {
        &:hover {
          path {
            stroke: darken($primary-orange, 10%);
          }
        }
      }
    }

    &.fill {
      path {
        fill: $primary-orange;
      }
      &.hoverable {
        &:hover {
          path {
            fill: darken($primary-orange, 10%);
          }
        }
      }
    }
  }

  &.red {
    &.stroke {
      path {
        stroke: $primary-red;
      }
      &.hoverable {
        &:hover {
          path {
            stroke: darken($primary-red, 10%);
          }
        }
      }
    }

    &.fill {
      path {
        fill: $primary-red;
      }
      &.hoverable {
        &:hover {
          path {
            fill: darken($primary-red, 10%);
          }
        }
      }
    }
  }

  &.green {
    &.stroke {
      path {
        stroke: #63cc6d;
      }
      &.hoverable {
        &:hover {
          path {
            stroke: darken(#63cc6d, 10%);
          }
        }
      }
    }

    &.fill {
      path {
        fill: #63cc6d;
      }
      &.hoverable {
        &:hover {
          path {
            fill: darken(#63cc6d, 10%);
          }
        }
      }
    }
  }
}
