@import "variables";

.icon {
  background: url("../../../../images/trash-can.svg") no-repeat center center
    $grey-7;
  display: block;
  background-size: 40% auto;
  margin: 0 auto 16px auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
