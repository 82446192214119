@import "variables";

html,
body {
  background: $grey-2;
  height: 100%;
}

// Using old-school centering technique here as this content
// is specifically rendered only on ancient browsers
.container {
  width: 500px;
  height: 300px;
  margin-top: -150px;
  margin-left: -250px;
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  color: white;

  h1 {
    font-weight: normal;
    font-size: 20px;
    margin-top: 40px;
    color: white;
  }

  p {
    margin: 24px auto;
    font-size: 16px;
    line-height: 23px;
    color: white;
  }

  a,
  a:visited,
  a:active {
    color: white;
  }
}
