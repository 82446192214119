@import "variables";

.tab-header {
  border-bottom: 1px solid $grey-6;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.tab-body {
  padding-top: 10px;
}
