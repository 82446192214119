:global {
  .mapboxgl-marker {
    pointer-events: none;
  }
}

.marker {
  display: block;
  width: 34px;
  height: 56px;
  background-image: url("../../../images/map-marker.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 100%;

  @media print {
    display: none;
  }
}
