@import "variables";
@import "responsive";

.container {
  background: #fff;
  height: -webkit-fill-available; // Fix height on iOS Safari

  @include lg {
    height: 100vh;
  }
}

.no-permit-message {
  display: flex;
  justify-content: center;
  padding-top: 16px;
  margin: 64px 16px 16px 16px;
}

.header {
  margin-bottom: 24px;

  & h2 {
    font-weight: 500;
    font-size: 18px;
    color: $grey-1;
    margin: 0;
    line-height: 145%;
  }

  & h3 {
    font-size: 14px;
    color: $grey-3;
    font-weight: normal;
    margin: 8px 0 0;
    line-height: 20px;

    @include lg {
      margin: 0;
    }
  }
}

.input {
  textarea {
    resize: none;
  }

  &.with-tooltip {
    fieldset {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      & > :last-child {
        width: 100%;
      }
    }
  }
}

.data-source-tooltip {
  padding-top: 4px;
  height: 22px;
}
