@import "variables";
@import "responsive";

.fieldset {
  padding: 0px;
  border: 0 none;
  margin: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    margin-bottom: 8px;
  }
}
