.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  margin-right: 10px;
  margin-top: 4px;
  max-width: 12px;
}

.text {
  height: 100%;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}
