.tooltip {
  position: absolute;
  display: inline-block;
  color: white;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  transform: translate(10px, -50%);
  font-size: 10px;
  line-height: 20px;
  font-weight: 600;
  padding: 0 8px;
  border-radius: 8px;
  white-space: nowrap;
}
