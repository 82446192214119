@import "responsive";
@import "variables";

.textarea {
  width: 100%;
  flex-grow: 1;
  resize: none;
  padding: 8px;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid $grey-6;
  min-height: 160px;
  &.invalid {
    border-color: $primary-red;
  }
}
