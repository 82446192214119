@import "variables";

.form {
  font-family: Helvetica, sans;
  margin: 24px;
}

.form-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  margin: 24px 24px 12px;
  color: black;
}

.section-title {
  margin: 32px 0 24px;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  color: black;
}

.alert {
  background: #fdefee; //bgBannerCritical
  border: 1px solid $primary-red;
  margin: 16px 0;
  padding: 16px;
  color: $primary-red;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
}

.fieldset {
  margin-top: 24px;
}

.input-wrapper {
  margin-top: 18px;
  flex-grow: 1;

  // Wrapper positions this absolutely, which breaks for us when we have notes
  span {
    position: initial !important;
  }
}

.datepicker {
  background-color: white;
}

.radio-input {
  label {
    font-size: 16px;
  }
}

.input-row {
  display: flex;

  > *:not(:first-child) {
    margin-left: 12px;
  }
}

.note {
  font-size: 12px;
  color: $grey-4;
  margin-top: 6px;
  display: block;
}

.callout {
  color: $primary-blue;
}

.breakaway-walls {
  margin-top: 10px;

  input {
    visibility: hidden;
  }
}
