@import "variables";

$page-margin: 85px;

.dashboard {
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px $page-margin;
    background: white;
    border-bottom: 1px solid $grey-6;

    h1 {
      font-weight: 500;
      font-size: 24px;
      color: $grey-1;
      margin: 0;
    }

    .go-to-accounts {
      margin-left: 10px;
    }
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
    margin: 32px $page-margin 22px;
    color: $grey-2;
  }

  ul {
    list-style: none;
    margin: 22px $page-margin 40px;
    padding: 0 24px;
    background: white;

    border: 1px solid $grey-6;
    box-shadow: 0px 2px 4px rgba(221, 221, 221, 0.5);
    border-radius: 4px;

    li {
      display: flex;
      height: 74px;
      align-items: center;

      &:not(:last-child) {
        border-bottom: 1px solid $grey-6;
      }

      h3 {
        font-weight: normal;
        font-size: 18px;
        margin: 0;
        color: $grey-2;
        flex-grow: 1;
      }

      span {
        font-weight: normal;
        font-size: 14px;
        color: $grey-3;

        &.highlight {
          color: $grey-2;
        }

        &:not(:last-child) {
          margin-right: 32px;
        }
      }
    }
  }
}
