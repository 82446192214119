@import "variables";
@import "responsive";

.addresses {
  .address-row {
    display: inline-flex;
    flex-direction: row;
    // 30px is roughly the width of the radio button itself
    width: calc(100% - 30px);
    justify-content: space-between;
    color: $grey-3;
    font-size: 16px;

    .address-row-legend {
      color: $grey-4;
    }
  }
  label {
    width: 100%;
  }
}
